/*
This exports only zendesk sdk as a seperate js file . See configuration in "webpack.config.js" for details
*/

//import "core-js/stable";

import "regenerator-runtime/runtime";

import "../polyfills/prepend"

import {initZendesk, updateZendesk, getZendeskTokens, openFeedback, openArticles, openChat, logout } from "./index"

window.FAMZendesk = { initZendesk, updateZendesk, getZendeskTokens, openFeedback, openArticles, openChat, logout }